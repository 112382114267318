<template>
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          <h3 class="cart-title">
            <a data-action="collapse">Research Report
              <span :class="{'d-none': !file_present.id}">
                <i
                  data-feather="check"
                  class="text-success"
                />
              </span>
              <span :class="{'d-none': file_present.id}">
                <i
                  data-feather="bell"
                  class="text-danger"
                />
              </span>
            </a>
          </h3> <!-- la campana roja indica que tiene algún valor a cero -->
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <a data-action="collapse"><i data-feather="chevron-down" /></a>
              </li>
            </ul>
          </div>
          <!-- <div class="row">
            <div class="col">
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
            </div>
          </div> -->
        </div>
        <div class="card-content collapse show">
          <div class="card-body">
            <div class="mb-1">
              <div>
                <small>
                  Please upload a summary of your scientific activity since your incorporation or your last evaluation (maximum 2 pages). The structure of the summary is open but you should know that the evaluators will have to form an opinion on these topics:
                  <ul>
                    <li>Research impact</li>
                    <li class="ms-1">Relevance and impact of research topic</li>
                    <li class="ms-1">Publications output (quantity, quality, impact)</li>
                    <li>Research Leadership</li>
                    <li class="ms-1">Research group</li>
                    <li class="ms-1">Grants, funds and honours obtained</li>
                  </ul>
                </small>
              </div>
              <div
                v-if="convo.days > 0"
                class="input-group custom-file-button"
              >
                <label
                  class="input-group-text"
                  for="formFile"
                >Select file</label>
                <input
                  id="formFile"
                  :ref="`file-present-report`"
                  class="form-control"
                  type="file"
                  @change="uploadFile('file-present-report')"
                >
              </div>
              <p
                v-if="!file_present.id"
                class="text-danger"
              >
                <small>Upload: pending</small>
              </p>
              <p v-else>
                <small>Uploaded: {{ file_present.created_at_formatted }}</small>
              </p>
            </div>
            <a
              v-if="file_present.id"
              :href="file_present.url"
              :disabled="!file_present.id"
              target="_blank"
              :class="{'disabled': !file_present.id }"
              class="btn btn-sm btn-dark"
            >Open document <i data-feather="share" /></a>
            <a
              v-if="file_present.id"
              class="btn btn-sm btn-danger ms-1"
              @click="deleteDoc(file_present.id)"
            >Delete document <i data-feather="trash" /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header">
          <h3 class="cart-title">
            <a data-action="collapse">Future Research Plan
              <span :class="{'d-none': !file_future.id}">
                <i
                  data-feather="check"
                  class="text-success"
                />
              </span>
              <span :class="{'d-none': file_future.id}">
                <i
                  data-feather="bell"
                  class="text-danger"
                />
              </span>
            </a>
          </h3> <!-- la campana roja indica que tiene algún valor a cero -->
          <div class="heading-elements">
            <ul class="list-inline mb-0">
              <li>
                <a data-action="collapse"><i data-feather="chevron-down" /></a>
              </li>
            </ul>
          </div>
          <!-- <div class="row">
            <div class="col">
              <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
            </div>
          </div> -->
        </div>
        <div class="card-content collapse show">
          <div class="card-body">
            <div class="mb-1">
              <p>
                <small>
                  Please upload a document with your future research plan (maximum 1 page).
                </small>
              </p>
              <div
                v-if="convo.days > 0"
                class="input-group custom-file-button"
              >
                <label
                  class="input-group-text"
                  for="inputGroupFile"
                >Select file</label>
                <input
                  id="inputGroupFile"
                  :ref="`file-future-report`"
                  class="form-control"
                  type="file"
                  @change="uploadFile('file-future-report')"
                >
              </div>
              <p
                v-if="!file_future.id"
                class="text-danger"
              >
                <small>Upload: pending</small>
              </p>
              <p v-else>
                <small>Uploaded: {{ file_future.created_at_formatted }}</small>
              </p>
            </div>
            <a
              v-if="file_future.id"
              :href="file_future.url"
              :disabled="!file_future.id"
              :class="{'disabled': !file_future.id }"
              target="_blank"
              class="btn btn-sm btn-dark"
            >Open document <i data-feather="share" /></a>
            <a
              v-if="file_future.id"
              class="btn btn-sm btn-danger ms-1"
              @click="deleteDoc(file_future.id)"
            >Delete document <i data-feather="trash" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      loading: false,
      file_present: {},
      file_future: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
      convo: 'convo/convo',
    }),
  },
  async mounted() {
    this.getFiles()
  },
  methods: {
    async getFiles() {
      this.file_present = {}
      this.file_future = {}
      const files = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-files`, { user_id: this.user.id })

      files.data.data.forEach(e => {
        if (e.type == 8) {
          this.file_future = e
        } else {
          this.file_present = e
        }
      })
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
    },
    async uploadFile(name) {
      const file = this.$refs[name].files[0]

      const fileData = {
        name: file.name,
        original_name: file.name,
        description: '',
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        to: '',
        file,
        url: URL.createObjectURL(file),
        aux: name,
        user_id: this.user.id,
      }

      await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/save-files`, fileData)
      await Vue.prototype.$saveFiles([fileData])
      this.$toastr.success('', 'Document uploaded successfully!')
      this.getFiles()
    },
    deleteDoc(fileId) {
      Vue.swal({
        title: 'Delete the file?',
        text: "Are you sure? You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete!',
      }).then(result => {
        if (result.isConfirmed) {
          if (fileId) {
            this.$store.dispatch('lines/deleteFile', fileId).then(() => {
              this.getFiles()
            })
            Vue.swal('File deleted successfully!', '', 'success')
          }
        }
      })
    },
  },
}
</script>
