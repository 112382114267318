<template>
  <!-- BEGIN: Content-->
  <div
    v-if="convo"
    class="app-content content "
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ convo.title }} {{ userPcmember ? `- ${userPcmember.name}` : '' }}
              </h2>
              <small v-if="!userPcmember"><small class="badge bg-light-danger">{{ convo.days }} days to complete the documentation {{ convo.days == 0 ? '- Deadline ended' : '' }}</small></small>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="convo.id"
        class="content-body content-body--senior-call"
      >
        <div class="row">
          <SearchResearcher
            :item="item"
            :only-promotion-researcher="convo.id"
          />

          <IcreaResume v-if="loggedUser.roles.includes('pc-member') && !loading" />

          <div
            v-if="!loggedUser.roles.includes('pc-member')"
            class="col-12 pe-0 ps-0"
          >
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a
                    v-b-toggle="`collapseDescription`"
                    data-action="collapse"
                  >Guidelines</a><i data-feather="chevron-down" />
                </h3>
              </div>
              <div class="card-content">
                <b-collapse
                  :id="`collapseDescription`"
                >
                  <div class="card-body">
                    <div v-html="convo.description" />
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
          <div
            v-if="!loggedUser.roles.includes('pc-member')"
            class="col-8 pe-0 ps-0"
          >
            <FileBox v-if="user.id && !loading && convo.id" />
          </div>

          <!-- CV -->
          <div
            v-if="user.id && !loading && convo.id && !loggedUser.roles.includes('pc-member')"
            class="col-sm-4"
          >
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">CV - Promotion</a>
                </h3> <!-- la campana roja indica que tiene algún valor a cero -->
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li v-if="!loading">
                      <a
                        :href="$router.resolve({name: 'researcher.personal-data.index', query: { actAs: $route.query.actAs, goTo: 'cv' }}).href"
                        target="_blank"
                      ><i data-feather="edit-3" /></a>
                    </li>
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div
                    v-if="user.CVFile.length > 0"
                    class="alert alert-success"
                  >
                    <div class="alert-body">
                      <div class="row d-flex align-items-center">
                        <div class="col-sm-6">
                          <p class="text-dark">
                            <strong>{{ user.CVFile[0].name }}</strong>
                          </p>
                          <p class="text-dark">
                            <small><em>Last update: {{ user.CVFile[0].created_at_formatted }}</em></small>
                          </p>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-sm-end">
                          <p>
                            <a
                              title="open file"
                              target="_blank"
                              class="btn text-dark"
                              :href="user.CVFile[0].url"
                            ><i data-feather="eye" /> View CV</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="alert alert-danger p-1"
                  >
                    No CV uploaded. Please, go to your profile section and fill it up.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="user.id && !loading"
            class="content-header-right text-md-end col-12 d-md-block d-none"
            :style="{'margin-top': !loggedUser.roles.includes('pc-member') ? '-50px' : ''}"
          >
            <div
              v-if="convo.days > 0"
              class="mb-1 breadcrumb-right"
            >
              <a
                class="btn btn-icon btn-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add peer"
                :href="!userPcmember ? $router.resolve({ name: 'researcher.promotion-call.add-peer' }).href : $router.resolve({ name: 'evaluator.calls.evaluations.add-peers', params: {
                  userPcmember
                } }).href"
                target="_blank"
              ><i data-feather="plus" /> Add peer</a>
            </div>
          </div>

          <CompleteTable
            v-if="user.id && !loading"
            :sorted-field="sortedField"
            :data="suitablePeers"
            :sort-mapping="sortMapping"
            :title="'Suitable peers'"
            :total="suitablePeers.length"
            :filter-path="'users/filterPeers'"
            :hide-bottom="true"
            :show-actions-column="convo.days > 0"
          >
            <template v-slot="{field, showInfoSheet}">
              <td
                v-if="convo.days > 0"
                class="text-end"
              >
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <i data-feather="more-vertical" />
                  </template>
                  <div
                    class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                  >
                    <b-dropdown-item @click="movePeer(3, field.id)">
                      <i
                        class="me-50"
                        data-feather="moon"
                      /> Send to blacklist
                    </b-dropdown-item>
                    <b-dropdown-item @click="movePeer(2, field.id)">
                      <i
                        class="me-50"
                        data-feather="x"
                      /> Delete
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </td>
              <td
                v-else
                class="text-end"
              >
                <div class="badge badge-light-danger">
                  Deadline ended
                </div>
              </td>
            </template>
            <template #customfilters>
              <div
                v-if="loggedUser.roles.includes('super-admin')"
                class="mb-1"
              >
                <label
                  for="nameseniorcall"
                  class="form-label"
                >Researcher</label>
                <v-select
                  v-model="filters.researcher"
                  label="name"
                  :options="users"
                  :get-option-key="option => option.id"
                  @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                  @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                />
              </div>
              <div class="mb-1">
                <label
                  for="nameseniorcall"
                  class="form-label"
                >Title</label>
                <input
                  v-model="filters.title"
                  type="text"
                  class="form-control"
                  name="nameseniorcall"
                  @change="saveFilter({ customLabel: true, name: `Title: ${$event.target.value}`, value: $event.target.value }, 'title')"
                >
              </div>
              <div class="mb-1">
                <label
                  for="areaseniorcall"
                  class="form-label"
                >Area</label>
                <v-select
                  v-if="areas.length !== undefined"
                  v-model="filters.area"
                  label="name"
                  :options="areas"
                  :get-option-key="option => option.id"
                  @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event }, 'area')"
                >
                  <option
                    value=""
                    selected
                  >
                    All areas
                  </option>
                </v-select>
              </div>
              <div class="mb-1">
                <label
                  for="areaseniorcall"
                  class="form-label"
                >Publish in web</label>
                <select
                  v-model="filters.publish_web"
                  class="form-select"
                  @input="saveFilter({ customLabel: true, name: `Publish in web: ${$event.target.value == 'Publish in web' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No') }`, value: `${$event.target.value}` }, 'publish_web')"
                >
                  <option
                    value=""
                    selected
                  >
                    All
                  </option>
                  <option
                    value="Publish in web"
                  >
                    Yes
                  </option>
                  <option
                    value="Don't publish in web"
                  >
                    No
                  </option>
                </select>
              </div>
              <div class="mb-1">
                <label
                  for="areaseniorcall"
                  class="form-label"
                >Images</label>
                <select
                  v-model="filters.images"
                  class="form-select"
                  @input="saveFilter({ customLabel: true, name: 'Images: ' + ($event.target.value === '1' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'images')"
                >
                  <option
                    value=""
                    selected
                  >
                    All
                  </option>
                  <option
                    value="1"
                    selected
                  >
                    Yes
                  </option>
                  <option
                    value="0"
                    selected
                  >
                    No
                  </option>
                </select>
              </div>
              <div class="mb-1">
                <label
                  for="areaseniorcall"
                  class="form-label"
                >Files</label>
                <select
                  v-model="filters.files"
                  class="form-select"
                  @input="saveFilter({ customLabel: true, name: 'Files: ' + ($event.target.value === '1' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'Files')"
                >
                  <option
                    value=""
                    selected
                  >
                    All
                  </option>
                  <option
                    value="1"
                    selected
                  >
                    Yes
                  </option>
                  <option
                    value="0"
                    selected
                  >
                    No
                  </option>
                </select>
              </div>
            </template>
          </CompleteTable>

          <div class="col-6 ps-0">
            <div
              v-if="!loading && user.id"
              id="blacklisted"
              class="card card--table"
            >
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4>Host</h4>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Host name</th>
                      <th>Email</th>
                      <th>Evaluation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ user.host_data ? user.host_data.name_host : '-' }}</td>
                      <td>{{ user.host_data ? user.host_data.email_host : '-' }}</td>
                      <td>
                        <span
                          v-if="user.host_data && user.host_data.evaluation"
                          class="badge badge-light-success"
                        >Completed</span>
                        <span
                          v-else
                          class="badge badge-light-danger"
                        >Pending</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6 pe-0">
            <div
              v-if="!loading && user.id"
              id="blacklisted"
              class="card card--table"
            >
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4><strong>{{ blacklistedPeers.length }}</strong> blacklisted peers</h4>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Peer</th>
                      <th>Institution</th>
                      <th
                        v-if="convo.days > 0"
                        class="text-end"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="blacklistedPeer in blacklistedPeers"
                      :key="blacklistedPeer.id"
                    >
                      <td>{{ blacklistedPeer.peer }}</td>
                      <td>{{ blacklistedPeer.institution ? blacklistedPeer.institution.name : '-' }}</td>
                      <td
                        v-if="convo.days > 0"
                        class="text-end"
                      >
                        <b-dropdown
                          variant="link"
                          no-caret
                          toggle-class="p-0"
                          right
                        >
                          <template #button-content>
                            <i data-feather="more-vertical" />
                          </template>
                          <div
                            class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                          >
                            <b-dropdown-item @click="movePeer(4, blacklistedPeer.id)">
                              <i
                                class="me-50"
                                data-feather="x"
                              /> Remove from blacklist
                            </b-dropdown-item>
                          </div>
                        </b-dropdown>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            v-if="loggedUser.roles.includes('pc-member') && user.id && !loading"
            class="col-12 card"
          >
            <div class="card-header d-flex align-items-center justify-content-between">
              <div>
                <h4>Researcher's report:
                  <a
                    v-if="file_present.id"
                    :href="file_present.url"
                    :disabled="!file_present.id"
                    target="_blank"
                    :class="{'disabled': !file_present.id }"
                  ><i data-feather="file" /></a>
                  <span v-else>No uploaded</span>
                </h4>
              </div>
            </div>
          </div>
          <div
            v-if="loggedUser.roles.includes('pc-member') && user.id && !loading"
            class="col-12 card"
          >
            <div class="card-header d-flex align-items-center justify-content-between">
              <div>
                <h4>Future Research plan:
                  <a
                    v-if="file_future.id"
                    :href="file_future.url"
                    :disabled="!file_future.id"
                    :class="{'disabled': !file_future.id }"
                    target="_blank"
                  ><i data-feather="file" /></a>
                  <span v-else>No uploaded</span>
                </h4>
              </div>
            </div>
          </div>

          <Publications
            v-if="user.id && !loading"
            :user-id="user.id"
            :toggled="false"
            :year-between="yearBetween"
            :field="{label: 'Publications'}"
            :from-promotion="true"
            @goToIndex="goToIndex"
          />

          <GrantsResource
            v-if="user.id && !loading"
            :user-id="user.id"
            :toggled="false"
            :year-between="yearBetween"
            :field="{label: 'Grants & Resources'}"
            @goToIndex="goToIndex"
          />

          <Conferences
            v-if="user.id && !loading"
            :user-id="user.id"
            :toggled="false"
            :year-between="yearBetween"
            :field="{label: 'Conferences, workshops and invited talks'}"
            @goToIndex="goToIndex"
          />

          <CoursesAndSeminars
            v-if="user.id && !loading"
            :user-id="user.id"
            :toggled="false"
            :year-between="yearBetween"
            :field="{label: 'Courses and Seminars'}"
            @goToIndex="goToIndex"
          />

          <PhDTheses
            v-if="user.id && !loading"
            :user-id="user.id"
            :toggled="false"
            :year-between="yearBetween"
            :field="{label: 'PhD, Master Theses and TFG'}"
            @goToIndex="goToIndex"
          />

          <ManagerialActivities
            v-if="user.id && !loading"
            :user-id="user.id"
            :toggled="false"
            :year-between="yearBetween"
            :field="{label: 'Managerial activities'}"
            @goToIndex="goToIndex"
          />

          <InternalNotes
            v-if="user.id && !loading"
            :notes="convo.notes"
            @saveNewNote="saveNewNote"
          />
        </div>
        <div
          v-if="loading"
          class="col-12"
        >
          <div class="loading custom-loading">
            <div class="effect-1 effects" />
            <div class="effect-2 effects" />
            <div class="effect-3 effects" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import 'vue2-datepicker/index.css'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import InternalNotes from '../../partials/components/InternalNotes.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import FileBox from './components/FileBox.vue'
import Publications from '../scientific-production-report/components/Publications.vue'
import GrantsResource from '../scientific-production-report/components/GrantsResource.vue'
import Conferences from '../scientific-production-report/components/Conferences.vue'
import CoursesAndSeminars from '../scientific-production-report/components/CoursesAndSeminars.vue'
import PhDTheses from '../scientific-production-report/components/PhDTheses.vue'
import ManagerialActivities from '../scientific-production-report/components/ManagerialActivities.vue'
import IcreaResume from '../../evaluator/promotion/partials/IcreaResume.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    SearchResearcher,
    InternalNotes,
    FileBox,
    Publications,
    GrantsResource,
    Conferences,
    CoursesAndSeminars,
    PhDTheses,
    ManagerialActivities,
    IcreaResume,
    BCollapse,
  },
  data() {
    return {
      loading: true,
      userId: null,
      item: {
        user: null,
      },
      sortedField: 'Peer',
      defaultFields: [
        {
          name: 'Peer',
          checked: true,
          order: 1,
        },
        {
          name: 'Institution',
          checked: true,
          order: 2,
        },
        {
          name: 'Email',
          checked: true,
          order: 3,
        },
        {
          name: 'Erc',
          checked: true,
          order: 4,
        },
        {
          name: 'Status',
          checked: true,
          order: 5,
        },
        {
          name: 'Evaluation',
          checked: true,
          order: 6,
        },
      ],
      sortMapping: {
        Peer: 'peer',
        Institution: 'institution',
        Email: 'email',
        Erc: 'erc.code',
        Status: 'status',
        // Evaluation: 'evaluation',
        'Added by': 'added_by',
      },
      userPcmember: this.$route.params.userPcmember,
      researcher_id: this.$route.params.researcher_id,
      file_present: {},
      file_future: {},
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      user: 'users/user',
      peers: 'users/peers',
      convo: 'convo/convo',
      filters: 'filters/filters',
      areas: 'areas/areas',
    }),
    realUser() {
      return this.item.user
    },
    suitablePeers() {
      return this.peers.filter(e => !e.pivot.blacklist)
    },
    blacklistedPeers() {
      return this.peers.filter(e => e.pivot.blacklist)
    },
    yearBetween() {
      return {
        from: ((this.user.next_promotion_year - 1) - this.user.total_years_prom),
        to: (this.user.next_promotion_year - 1),
      }
    },
  },
  watch: {
    async realUser() {
      this.loading = true
      await this.$store.dispatch('users/fetchUser', this.item.user.id)
      this.$store.dispatch('preview/togglePersonalInformation', false)
      this.$store.dispatch('users/setUserManagement', this.user.id)
      await this.$store.dispatch('users/filterPeers', this.filters)
      if (this.convo.id) {
        this.$store.dispatch('users/getEvaluatorHistory', { user: this.user.id, convo: this.convo.id })
      }
      this.loading = false
    },
  },
  async mounted() {
    this.$store.dispatch('users/cleanUser')
    if (this.researcher_id) {
      await this.$store.dispatch('users/fetchUser', this.researcher_id)
      this.$store.dispatch('users/setUserManagement', this.user.id)
      await this.$store.dispatch('users/filterPeers', this.filters)
      this.item.user = this.user
    } else if (this.userPcmember) {
      await this.$store.dispatch('users/fetchUser', this.userPcmember.id)
      this.$store.dispatch('users/setUserManagement', this.user.id)
      await this.$store.dispatch('users/filterPeers', this.filters)
      this.$store.dispatch('preview/togglePersonalInformation', false)
    } else if (this.loggedUser.roles.includes('pc-member') && !this.loggedUser.roles.includes('researcher')) {
      this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
    }

    if ((!this.loggedUser.roles.includes('super-admin') && !this.loggedUser.roles.includes('pc-member')) || this.loggedUser.roles.includes('researcher')) {
      await this.$store.dispatch('users/fetchUser', this.loggedUser.id)
      this.$store.dispatch('users/setUserManagement', this.user.id)
      await this.$store.dispatch('users/filterPeers', this.filters)
      this.$store.dispatch('preview/togglePersonalInformation', false)
    }

    await this.$store.dispatch('convo/getActivePromotion')
    await this.$store.dispatch('areas/filter', [])

    if (this.user.id) {
      this.$store.dispatch('users/getEvaluatorHistory', { user: this.user.id, convo: this.convo.id })
      const files = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-files`, { user_id: this.user.id })

      files.data.data.forEach(e => {
        if (e.type == 8) {
          this.file_future = e
        } else {
          this.file_present = e
        }
      })
    }

    if (!this.convo.id) {
      this.$router.push({ name: 'researcher.dashboard' })
    }

    this.loading = false
    await this.$store.dispatch('modals/fetchUserFields', 'peers')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: !this.loggedUser.roles.includes('researcher') ? this.defaultFields.concat([{
          name: 'Added by',
          checked: true,
          order: 7,
        }]) : this.defaultFields,
        table: 'peers',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
      this.$store.dispatch('preview/togglePersonalInformation', false)
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'educations', url: `${Vue.prototype.$groupUrl}/educations/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    saveNewNote(note) {
      this.hazard.notes.push(note)
    },
    async goToIndex(route) {
      let url = ''
      if (Array.isArray(route)) {
        if (route[0] && route[0].id) {
          url = this.$router.resolve({ name: route[1], params: route[0] }).href
        } else {
          url = this.$router.resolve({ name: route[1], query: route[0] }).href
        }
      }
      window.open(url, '_blank')
    },
    movePeer(blacklist, id) {
      Vue.swal({
        title: 'Are you sure?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          if (blacklist) {
            this.$store.dispatch('users/setPeers', { actions: { assign: blacklist }, itemIds: [id], researcherId: this.user.id }).then(() => {
              this.$toastr.success('', 'Actions applied successfully!')
            })
          } else {
            this.$store.dispatch('users/setPeers', { actions: { assign: blacklist }, itemIds: [id], researcherId: this.user.id }).then(() => {
              this.$toastr.success('', 'Actions applied successfully!')
            })
          }
          this.$store.dispatch('users/setUserManagement', this.user.id)
          this.$store.dispatch('users/filterPeers', this.filters).then(() => {
            setTimeout(() => {
              // eslint-disable-next-line no-undef
              feather.replace({
                width: 14,
                height: 14,
              })
            }, 500)
          })
          setTimeout(() => {
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 500)
        }
      })
    },
  },
}
</script>
